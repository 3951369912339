import { http } from './config'
import { httpNoLoading } from './configNoLoading'

export default {
    createOrcamento: (data) => {
        return http.post('orcamento', data)
    },
    getByCodigo: (id) => {
        return http.get('orcamento/' + id)
    },
    getOrcamentoItens: (id) => {
        return http.get('orcamento/' + id + '/itens')
    },
    printOrcamento: (id, status = null) => {
        if(status){
            return http.get('orcamento/' + id + '/imprimir/'+status , {
                responseType: 'blob',
                timeout: 60000,
            })
        } else {
            return http.get('orcamento/' + id + '/imprimir', {
                responseType: 'blob',
                timeout: 60000,
            });
        }
    },
    getProductChangeAlerts: (id) => {
        return httpNoLoading.get('orcamento/' + id + '/alertas-de-mudanca')
    },
    getProductItemChangeAlerts: (orcamento_id, orcamento_item_id) => {
        return httpNoLoading.get('orcamento/' + orcamento_id + '/item/' + orcamento_item_id + '/alertas-de-mudanca')
    },
    getProductBudgetOutOfStock: (orcamento_id, orcamento_item_id) => {
        return httpNoLoading.get('orcamento/' + orcamento_id + '/item/' + orcamento_item_id + '/alertas-mudanca-estoques');
    },
    getProductBudgetPriceMin: (orcamento_id, orcamento_item_id) => {
        return httpNoLoading.get('orcamento/' + orcamento_id + '/item/' + orcamento_item_id + '/alertas-mudanca-preco-minimo');
    },
    getProductMultiple: (orcamento_id, orcamento_item_id) => {
        return httpNoLoading.get('orcamento/' + orcamento_id + '/item/' + orcamento_item_id + '/alertas-multiplos');
    },
    cancelOrcamento: (id, motivoCancel) => {
        let data ={
            'motivo': motivoCancel
        }
        return http.put('orcamento/' + id + '/cancelar', data)
    },
    sendOrcamento: (id, filial) => {
        return http.put('orcamento/' + id + '/enviar')
    },
    duplicarOrcamento: (orcamento) => {
        return http.post('orcamento/duplicar', orcamento);
    },
    saveOrcamento: (orcamento, filial) => {
        let data = {
            "contato": orcamento.contato,
            "data_validade": orcamento.data_validade,
            "previsao_fechamento": orcamento.previsao_fechamento,
            "cd_filial": filial
        };
        return http.put('orcamento/' + orcamento.id + '/salvar', data)
    },
    updateProducts: (id) => {
        return http.put('orcamento/' + id + '/atualizar-precos')
    },
    deleteOrcamento: (id) => {
        return http.delete('orcamento/' + id)
    },
    deleteOrcamentoItem: (orcamento_id, id) => {
        return http.delete('orcamento/' + orcamento_id + '/item/' + id)
    },
    addOrcamentoItem: (orcamento_id, data) => {
        return httpNoLoading.post('orcamento/' + orcamento_id + '/item', data)
    },
    saveOrcamentoItem: (item) => {
        let data = {
            "quantidade": item.quantidade,
            "margem_valor": item.margem_valor,
            "margem_percentual": item.margem_percentual,
            "cd_filial": item.cd_filial
        };
        return httpNoLoading.put('orcamento/' + item.orcamento_id + '/item/' + item.id, data)
    },
    saveObs: (orcamento_id, dados) => {
        let obsData = {
            "orcamento_id" : orcamento_id,
            "observacao": dados.observacao_text,
            "forma_pagamento": dados.forma_pagamento,
            "condicao_pagamento": dados.selectPayment
        };
        return http.put('orcamento/salvar-observacao', obsData)
    },
}