<template>
  <b-badge :variant="buildStatus.type">{{ buildStatus.text }}</b-badge>
</template>

<script>
export default {
  name: "ClienteStatus",
  props: {
    id: {
      type: String,
      default: "ClienteStatus"
    },
    name: {
      type: String,
      default: "ClienteStatus"
    },
    status: {
      type: String,
      default: ""
    }
  },
  computed: {
    buildStatus() {
      let status = {
        text: "",
        type: ""
      };
      switch (this.$props.status) {
        case "A":
          status.text = "Ativo";
          status.type = "success";
          break;

        case "P":
          status.text = "Pendente";
          status.type = "warning";
          break;

        case "R":
          status.text = "Reprovado";
          status.type = "danger";
          break;

        default:
          status.text = "Indefinido(" + this.$props.status + ")";
          status.type = "none";
          break;
      }
      return status;
    }
  }
};
</script>
