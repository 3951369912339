import { http } from './config'
import { httpNoLoading } from './configNoLoading'

export default {
    getByCodigo: (codigo) => {
        return http.get('cliente/codigo/' + codigo)
    },
    getBasicInfo: (codigo, withLoader = false) => {
        if (withLoader)
            return http.get('cliente/basic-info/' + codigo)
        else
            return httpNoLoading.get('cliente/basic-info/' + codigo)
    },
    getListaClientesCarrinho: (data) => {
        return httpNoLoading.get('cliente/lista-carrinho', data)
    },
    getAllClientes(){
        return http.get('cliente/lista-clientes');
    }
}