<template>
  <div class="clear-date-container">
    <datetime
      v-if="!readonly"
      v-on:click="onClick"
      :format="format"
      :type="type"
      :lanauage="'ptBR'"
      v-model="date"
      :input-class="'form-control'"
      :title="title"
      :phrases="{ ok: 'Ok', cancel: 'Cancelar' }"
      zone="local"
      value-zone="local"
    ></datetime>
    <b-form-input
      v-else-if="format.length <= 10"
      type="text"
      :value="date | formatDate"
      placeholder="Data de Validade"
      readonly
    />
    <b-form-input
      v-else
      type="text"
      :value="date | formatDateTime"
      placeholder="Data de Validade"
      readonly
    />
    <span v-if="hasDate && !readonly" v-on:click="clearDate" class="fa fa-close clear-date"></span>
  </div>
</template>

<style>
.theme-orange .vdatetime-popup__header,
.theme-orange .vdatetime-calendar__month__day--selected > span > span,
.theme-orange .vdatetime-calendar__month__day--selected:hover > span > span {
  background: #1985ac !important;
}
.theme-orange .vdatetime-year-picker__item--selected,
.theme-orange .vdatetime-time-picker__item--selected,
.theme-orange .vdatetime-popup__actions__button {
  color: #1985ac !important;
}

.clear-date {
  margin-right: 0.6rem;
  margin-left: -1.2rem;
  margin-top: 0.6rem;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
.clear-date-container {
  display: flex;
  width: 100%;
}
</style>

<script>
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";

export default {
  name: "MyDateTime",
  components: { Datetime },
  props: {
    id: {
      type: String,
      default: "MyDateTime"
    },
    format: {
      type: String,
      default: "dd/MM/yyyy"
    },
    type: {
      type: String,
      default: "date"
    },
    title: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    localDate: null
  }),
  computed: {
    date: {
      set: function(val) {
        if (val == null) this.localDate = null;
        this.localDate = val.replace("T", " ").split(".")[0];
        this.$emit("input", this.localDate);
      },
      get: function() {
        if (this.localDate == null) return null;
        return this.localDate.replace(" ", "T");
      }
    },
    hasDate() {
      return this.localDate != null && this.localDate.trim() != "";
    }
  },
  watch: {
    value: function(value) {
      this.localDate = value;
    }
  },
  methods: {
    onClick(event) {
      if (this.readonly) {
        event.stopPropagation();
      }
      return;
    },
    clearDate() {
      this.localDate = null;
    }
  }
};
</script>
